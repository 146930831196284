import React from 'react';

import {
  citymapper,
  monzo,
  nhs,
  oddbox,
  pricewaterhouseCoopers,
  trustpilot,
} from 'site-react/assets';
import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import {
  Asset,
  HorizontalRule,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';

import styles from './SignupSideContent.module.css';

const logos = [
  {
    alt: 'Pricewaterhouse Coopers',
    src: pricewaterhouseCoopers,
  },
  {
    alt: 'Citymapper',
    src: citymapper,
  },
  {
    alt: 'Monzo',
    src: monzo,
  },
  {
    alt: 'Trustpilot',
    src: trustpilot,
  },
  {
    alt: 'NHS',
    src: nhs,
  },
  {
    alt: 'Oddbox',
    src: oddbox,
  },
];

export default function SignupSideContent() {
  return (
    <aside className={styles.SignupSideContent}>
      <Heading level="2" type="title3">
        We’re trusted by 2,500+ of the world’s most exciting companies
      </Heading>

      <VerticalSpacing size="lg" />

      <ul className={styles['SignupSideContent-logos']}>
        {logos.map(({ alt, src }) => {
          return (
            <li key={alt}>
              <Asset alt={alt} sizingBehaviour="fill" src={src} />
            </li>
          );
        })}
      </ul>
      <VerticalSpacing size="lg" />
      <HorizontalRule />
      <VerticalSpacing size="lg" />
      <div className={styles['SignupSideContent-heading']}>
        <Heading level="3" type="title3">
          Hubble gives you the power to
        </Heading>
      </div>
      <VerticalSpacing size="sm" />

      <UnstyledList>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Instantly search 5,000+ offices"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Save your searches and curate shortlists of your favourite spaces"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Understand, manage and share everything workplace related with your team"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Get a free personal workplace advisor"
        />
      </UnstyledList>
    </aside>
  );
}
