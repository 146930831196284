// This file is driven by two sources:
// 1. The UK Government list of bank holidays for England and Wales: https://www.gov.uk/bank-holidays
// 2. A manual survey, sent out to some of our top hosts, to determine days
//     around Christmas/New Year's which are not available for viewings.

/**
 * This file is duplicated in site-cypress/cypress/fixtures/data/login/holidays.js in order to prevent end-to-end tests from
 * failing because we're trying to book a viewing on a date that is a holiday. If you are updating the values in this file please
 * duplicate them there.
 */

export default [
  '2022-12-24', // Christmas Eve, blocking based on feedback from hosts
  '2022-12-25', // Christmas Day
  '2022-12-26', // Boxing Day
  '2022-12-27', // Christmas Day (substitute day)
  '2022-12-28', // Christmas Day (substitute day)
  '2022-12-29', // Hubble Holiday break
  '2022-12-30', // Hubble Holiday break
  '2022-12-31', // New Year's Eve
  '2023-01-01', // New Year's Day
  '2023-01-02', // New Year’s Day (substitute day)
  '2023-04-07', // Good Friday
  '2023-04-10', // Easter Monday
  '2023-05-01', // Early May bank holiday
  '2023-05-08', // Bank holiday for the coronation of King Charles III
  '2023-05-29', // Spring bank holiday
  '2023-08-28', // Summer bank holiday
  '2023-12-25', // Christmas Day
  '2023-12-26', // Boxing Day
  '2023-12-27', // Hubble Holiday break
  '2023-12-28', // Hubble Holiday break
  '2023-12-29', // Hubble Holiday break
  '2023-12-30', // Hubble Holiday break
  '2023-12-31', // New Year's Eve
  '2024-01-01', // New Year's Day
  '2024-03-29', // Good Friday
  '2024-04-01', // Easter Monday
  '2024-05-06', // Early May bank holiday
  '2024-05-27', // Spring bank holiday
  '2024-08-26', // Summer bank holiday
  '2024-12-25', // Christmas Day
  '2024-12-26', // Boxing Day
  '2024-12-27', // Hubble Holiday break
  '2024-12-28', // Hubble Holiday break
  '2024-12-29', // Hubble Holiday break
  '2024-12-30', // Hubble Holiday break
  '2024-12-31', // New Year's Eve
  '2025-01-01', // New Year's Day
];
