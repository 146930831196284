/**
 * Figures out what values we should show in the Badges on the nano card.
 */
const getNanoCardLabels = (buildingType, specialOffer) => {
  const labels = [];
  if (buildingType) {
    const { desc, key, label } = buildingType;
    if (key && label) {
      labels.push({
        color: 'white',
        desc,
        isBorderVariant: true,
        label,
      });
    }
  }

  if (specialOffer) {
    labels.push({
      color: 'brandprimary',
      desc: specialOffer,
      label: 'Offer',
    });
  }

  return labels;
};

export default getNanoCardLabels;
