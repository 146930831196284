export default function toLondonTime(dateTime, keepLocalTime = false) {
  // Note the keepLocalTime parameter.
  //
  // In general, we _won't_ keepLocalTime if we're converting from the user's local
  // time to London time. But we _will_ keep local time when we've derived a specific
  // DateTime within this file - which means if you start from Saturday, and Sunday has
  // a DST change, then specifying Monday at 8:30 will _always_ be Monday at 8:30 in London,
  //
  // Be wary of this functionality.
  return dateTime.setZone('Europe/London', { keepLocalTime });
}
