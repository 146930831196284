import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { Badges, MaterialIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './SuccessModalViewingDetails.module.css';

const SuccessModalViewingDetails = ({
  buildingName,
  color,
  date,
  datetime,
  description,
  icon,
  isPartTime = null,
  labelledBy = null,
  onClick = () => {},
  pricePlan,
  time,
  title,
}) => (
  <section className={styles.SuccessModalViewingDetails}>
    <div
      className={cn(
        styles['SuccessModalViewingDetails-iconContainer'],
        styles[`SuccessModalViewingDetails-iconContainer--${color}`],
      )}
    >
      <MaterialIcon
        className={styles['SuccessModalViewingDetails-icon']}
        iconType={icon}
      />
    </div>
    <h1 className={styles['SuccessModalViewingDetails-title']} id={labelledBy}>
      {title}
    </h1>
    {isPartTime && (
      <div className={styles['SuccessModalViewingDetails-badge']}>
        <Badges.Badge color="brandSecondary" label="Part-time office viewing" />
      </div>
    )}
    <p className={styles['SuccessModalViewingDetails-description']}>
      {description}
    </p>
    <VerticalSpacing size="lg" />
    <time
      className={styles['SuccessModalViewingDetails-viewingDateTime']}
      data-testid="viewing-datetime"
      dateTime={datetime}
    >
      {date} {time}
    </time>
    <VerticalSpacing size="lg" />
    <div className={styles['SuccessModalViewingDetails-buildingName']}>
      {buildingName}
    </div>
    <div className={styles['SuccessModalViewingDetails-price']}>
      {pricePlan.type}
      <br />
      {pricePlan.priceFormattedPerMonth}
    </div>
    <VerticalSpacing size="lg" />
    <LinkAsButton
      analyticsMetadata={{
        location: 'success-modal',
        redirectLocation: '/dashboard/hq/viewings',
      }}
      href="/dashboard/hq/viewings"
      isBlock
      name="See all viewings"
      onClick={onClick}
      rel="noreferrer noopener nofollow"
      styleType="primary"
    >
      See all viewings
    </LinkAsButton>
  </section>
);

SuccessModalViewingDetails.propTypes = {
  /**
   * Name of the building from which user has requested a viewing
   */
  buildingName: PropTypes.string.isRequired,
  /**
   * Background color of the icon
   */
  color: PropTypes.oneOf(['blue-100', 'green-200']).isRequired,
  /**
   * The date of the viewing request.
   */
  date: PropTypes.string.isRequired,
  /**
   * The date and time of the viewing request.
   *
   * Accepted attributes listed here:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time#attributes
   */
  datetime: PropTypes.string.isRequired,
  /**
   * Description explaining the VR next steps:
   * this is a jsx element to allow for HTML
   * elements such as <strong> etc
   */
  description: PropTypes.element.isRequired,
  /**
   * Label used by material icons to display the right symbol
   */
  icon: PropTypes.oneOf(['check', 'hourglass_empty']).isRequired,
  /**
   * It should be true if the VR is for a part time office
   */
  isPartTime: PropTypes.bool,
  /**
   * ID used by aria-labelledBy tag on modal.
   * The element to which the ID must be assigned should be one that
   * adequately describes the content of the modal, so typically a
   * heading would be the right place to use this.
   */
  labelledBy: PropTypes.string,
  /**
   * Callback for when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * The user's chosen price plan: just the details that we want to present on success
   */
  pricePlan: PropTypes.shape({
    priceFormattedPerMonth: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  /**
   * The time of the viewing request.
   */
  time: PropTypes.string.isRequired,
  /**
   * Title copy, clarifies default or short notice
   */
  title: PropTypes.string.isRequired,
};

export default SuccessModalViewingDetails;
