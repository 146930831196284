import { useContext } from 'react';

import { UTMContext } from 'site-react/data/core/UTMContext';

function useUTM() {
  const context = useContext(UTMContext);
  if (!context) {
    throw new Error('useUTM must be used within a UTMProvider');
  }
  return context;
}

export default useUTM;
