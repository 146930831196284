import React from 'react';

import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import AdvisorPhotos from 'site-react/features/AdvisorPhotos';

import styles from './SuccessModalContactDetails.module.css';

export default function SuccessModalContactDetails() {
  return (
    <section className={styles.SuccessModalContactDetails}>
      <Heading level="2" type="title3">
        Want more options?
      </Heading>
      <VerticalSpacing size="md" />
      <Paragraph isCentered type="content1">
        Our team of expert advisors can help pick some more tailored
        recommendations for you. They can help you navigate the market or
        identify some off-market options. Easy. Get in touch below.
      </Paragraph>
      <AdvisorPhotos advisors={['BlueChai', 'BlueBeca', 'BlueHenryb']} />
      <Paragraph className="textStyling">
        Call us:{' '}
        <a
          className={styles['SuccessModalContactDetails-link']}
          href="tel:+442036080215"
        >
          +44 (0) 20 3608 0215
        </a>
        <br />
        Email:{' '}
        <a
          className={styles['SuccessModalContactDetails-link']}
          href="mailto:help@hubblehq.com"
        >
          help@hubblehq.com
        </a>
      </Paragraph>
    </section>
  );
}
