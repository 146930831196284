import cn from 'classnames';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { Button } from 'site-react/components/form';
import { CloseButton, OutboundLink } from 'site-react/components/navigation';
import { DrawerContext } from 'site-react/components/page/Drawer';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import {
  ImgixImage,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';
import {
  ADVISOR_GENERIC_IMAGE_SRC,
  ADVISOR_GENERIC_EMAIL,
  ADVISOR_GENERIC_PHONE_NO,
} from 'site-react/data/advisors';

import BasketItem from './components/BasketItem';
import styles from './ViewingBasket.module.css';

export default function ViewingBasket({
  advisor,
  likedRecommendationListItems,
  onNext,
  setRecommendationList,
  token,
}) {
  const { closeDrawer } = useContext(DrawerContext);

  return (
    <div className={styles.ViewingBasket}>
      <div className={styles['ViewingBasket-heading']}>
        <Heading level="1" type="title3">
          Your viewing schedule {likedRecommendationListItems.length}
        </Heading>
        <CloseButton onClick={closeDrawer} />
      </div>

      {likedRecommendationListItems.length ? (
        <UnstyledList className={styles['ViewingBasket-items']}>
          {likedRecommendationListItems.map((item) => (
            <li className={styles['ViewingBasket-item']} key={item.id}>
              <BasketItem
                item={item}
                setRecommendationList={setRecommendationList}
                token={token}
              />
            </li>
          ))}
        </UnstyledList>
      ) : (
        <>
          <VerticalSpacing size="md" />
          <Paragraph isMarginless>
            To add offices to your viewing schedule, hit “schedule viewings”
            from your list of offices
          </Paragraph>
        </>
      )}

      <div className={styles['ViewingBasket-actions']}>
        <div>
          <Button
            isCompact
            name="Add another office"
            onClick={closeDrawer}
            styleType="secondary"
            type="button"
          >
            <TextWithIcon
              iconName="add"
              iconPosition="left"
              text="Add another office"
            />
          </Button>
        </div>
      </div>

      <div
        className={cn(styles['ViewingBasket-advisor'], {
          [styles['is-genericAdvisor']]: !advisor,
        })}
      >
        <ImgixImage
          alt="Advisor"
          height={80}
          src={advisor?.image ?? ADVISOR_GENERIC_IMAGE_SRC}
          width={advisor?.image ? 80 : 217}
        />
        <Paragraph isMarginless>
          Send this list to your advisor to arrange your viewings for you.
        </Paragraph>
      </div>

      <Button
        disabled={!likedRecommendationListItems.length}
        isBlock
        isCompact
        name="Book viewings"
        onClick={onNext}
        type="button"
      >
        Book {likedRecommendationListItems.length} viewing
        {likedRecommendationListItems.length !== 1 ? 's' : ''} for me
      </Button>

      <VerticalSpacing size="md" />

      <div className={styles['ViewingBasket-contact']}>
        <Paragraph isMarginless>Or just get in touch</Paragraph>

        <VerticalSpacing size="xs" />

        <div className={styles['ViewingBasket-contactDetails']}>
          <OutboundLink
            href={`tel:${advisor?.tel ?? ADVISOR_GENERIC_PHONE_NO}`}
          >
            <TextWithIcon
              iconColor="neutral-900"
              iconName="call"
              iconPosition="left"
              text={advisor?.tel ?? ADVISOR_GENERIC_PHONE_NO}
            />
          </OutboundLink>

          <OutboundLink
            href={`mailto:${advisor?.email ?? ADVISOR_GENERIC_EMAIL}`}
          >
            <TextWithIcon
              iconColor="neutral-900"
              iconName="mail"
              iconPosition="left"
              text={advisor?.email ?? ADVISOR_GENERIC_EMAIL}
            />
          </OutboundLink>
        </div>
      </div>
    </div>
  );
}

ViewingBasket.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),

  likedRecommendationListItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),

  onNext: PropTypes.func.isRequired,

  setRecommendationList: PropTypes.func.isRequired,

  token: PropTypes.string.isRequired,
};
