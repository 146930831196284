import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

class ViewingsApi extends HubbleBaseApi {
  /**
   * Gets a list of viewings that the current user can view.
   *
   * @param {number} offset the offset to start from; used for pagination
   */
  async getViewings(queryParams) {
    return this.get('viewings-service', queryParams);
  }

  /**
   * Gets a list of viewings message threads that the current user can view.
   *
   * @param {boolean} host get threads for a building host (default is tenant)
   * @param {number} offset the offset to start from; used for pagination
   */
  async getViewingThreads(queryParams, opts) {
    return this.get('viewings-service/threads', queryParams, opts);
  }

  /**
   * Gets a viewing by its ID.
   *
   * @param {number} id the ID of the viewing to get
   */
  async getViewing(id, opts) {
    return this.get(`viewings-service/${id}`, undefined, opts);
  }

  /**
   * Gets a viewing message thread by its viewing ID.
   *
   * @param {number} id the viewing ID of the thread to get
   */
  async getViewingThread(id, opts) {
    return this.get(`viewings-service/${id}/thread`, undefined, opts);
  }

  /**
   * Gets the contacts for a viewing.
   *
   * @param {number} id the ID of the viewing to get contacts for
   */
  async getViewingContacts(id) {
    return this.get(`viewings-service/${id}/contacts`);
  }

  /**
   * Create a message.
   *
   * @param {string} content the message to be sent
   * @param {number} thread the id of the thread to post the message to
   */
  async createMessage(payload) {
    return this.post('viewings-service/messages', payload);
  }

  /**
   * Create a viewing.
   *
   * @param {number} number_of_desks number of desks the viewer is looking for
   * @param {number} price_plan_id the requested price plan product to be viewed
   * @param {string} viewing_datetime the requested date and time of the viewing
   */
  async createViewing(payload) {
    return this.post('viewings-service', payload);
  }

  /**
   * Creates the confirmation status for a viewing.
   *
   * @param {number} id the ID of the viewing to update
   * @param {object} payload data required to update the viewing object
   * with status of the viewing
   */
  async createViewingResponse(id, payload) {
    return this.post(`viewings-service/${id}/respond`, payload);
  }
}

export default ViewingsApi;
